import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../css/pages/secondchance.scss"

const SecondChance = () => {
  const hasForm = true
  const images = [""]

  return (
    <Layout>
      <SEO
        lang="en"
        title="Second Chance Program at Oral Surgery Specialists of Oklahoma"
        description="It is time for a brand new, healthy, beautiful smile."
      />

      <div className="sc-program">
        <div className="sc__hero">
          <div className="sc__hero--desktop">
            <img
              className="sc__hero-img"
              src="https://res.cloudinary.com/nuvolum/image/upload/v1659042618/Programs/second-chance-desktop-hero-image.jpg"
              alt="program banner"
            />
          </div>
          <div className="sc__hero--mobile">
            <img
              className="sc__hero-img"
              src="https://res.cloudinary.com/nuvolum/image/upload/v1659042617/Programs/second-chance-mobile-hero-image.jpg"
              alt="program banner"
            />
          </div>
        </div>

        <div className="sc__section">
          <div className="">
            <div className="sc__about-grid--content">
              <p className="sc__subheading">
                A Second Chance for a Healthy Smile{" "}
              </p>
              <br />
              <p>
                The Oral Surgery Specialists of Idaho team is proud to announce
                that this year’s Second Chance recipient is Mathew!
              </p>
              <p>
                Mathew, 31, is a General Manager at MOD Pizza and a dedicated
                single father to three children. He shared that his dental
                health has deteriorated to the point where he struggles to eat
                and smoke normally. This has had a significant impact on his
                self-confidence, both in his career and personal life. Mathew
                also faced a setback when he was turned down for a great job
                opportunity because he didn’t "look the part." He explained that
                a smile restoration would be life-changing, but as a single dad
                with limited financial resources, he cannot afford the necessary
                treatment.
              </p>
              <p>
                We hope that providing Mathew with the dental care he needs will
                improve his confidence and help him pursue new opportunities for
                both himself and his family.
              </p>

              <p className="sc__subheading mb-1 mt-3">Full-Arch Restoration</p>

              <p>
                Mathew will receive a full set of customized, artificial teeth
                at no cost. This tooth replacement solution is called full-arch
                restoration because it replaces a full dental arch of missing or
                failing teeth. Our team of experts will work closely with a
                restorative dentist to complete the treatment.{" "}
              </p>

              <p>
                Stay tuned to our{" "}
                <a
                  title="Follow us on Facebook"
                  href="https://www.facebook.com/oralsurgeryidaho/"
                  target="_blank"
                  rel="noopener">
                  Facebook
                </a>
                 and{" "}
                <a
                  title="Follow us on Instagram"
                  href="https://www.instagram.com/oralsurgery_idaho/"
                  target="_blank"
                  rel="noopener">
                  Instagram
                </a>{" "}
                to follow Mathew’s journey to a new smile!
              </p>
              <p>
                <i>
                  Learn more about{" "}
                  <Link
                    title="Learn more about dental implants"
                    to="/procedure/dental-implants-pocatello-id/">
                    dental implants
                  </Link>{" "}
                  and{" "}
                  <Link
                    title="Learn more about implant-supported dentures"
                    to="/procedure/implant-supported-dentures-bridges/">
                    implant-supported dentures
                  </Link>{" "}
                  to see how they can benefit your smile. Whether you need to
                  replace one tooth or an entire arch of teeth, our team has a
                  solution for you.
                </i>
              </p>
            </div>
          </div>
        </div>

        {/* <div className="sc__section close-sfs-slider close-sc-slider">
            <ImageSlider
              images={images}
              adaptiveHeight={true}
              useArrows={true}
            />
          </div> */}
      </div>
    </Layout>
  )
}

export default SecondChance
